import React from "react"
import { Link } from "gatsby"
import BGImage from "gatsby-background-image"
import moment from "moment"
import styled from "styled-components"
import {
  H2DarkGrey,
  B1DarkGrey,
  Btn1YellowTrans,
  colors,
} from "../../../styles/helpers"

const PostCardStyled = styled.article`
  width: 100%;
  margin: 2rem auto 4rem;
  padding: 2rem 0;
  border-bottom: solid 0.2rem ${colors.colorPrimary};

  @media (min-width: 768px) {
    margin: 2rem auto 4rem;
    padding: 2rem 5rem 7.5rem;
  }

  .postWrap {
    position: relative;
    width: 100%;
    @media (min-width: 768px) {
      padding-top: 10rem;
    }
  }

  .postImage {
    width: 100%;
    max-width: 55.8rem;
    height: 20rem;

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      height: 65%;
    }

    div {
      width: 100%;
      height: 100%;
    }
  }

  .postExcerpt {
    width: 100%;
    max-width: 75rem;
    margin: 0;
    margin-left: auto;
    padding: 3.5rem 0;
    background: ${colors.white};

    @media (min-width: 768px) {
      padding: 3.5rem;
    }

    h3 {
      ${H2DarkGrey};
    }

    p {
      ${B1DarkGrey};

      line-height: 1.64;
    }

    a {
      ${Btn1YellowTrans};
    }

    &__author {
      p {
        margin-bottom: 1.5rem;
      }
    }

    &__link {
      width: 100%;
      text-align: center;

      @media (min-width: 768px) {
        text-align: right;
      }
    }
  }
`

const PostCard = ({ post }) => {
  return (
    <PostCardStyled>
      <div className="postWrap">
        <div className="postImage">
          <BGImage
            tag="div"
            fluid={
              post.node.acf._kon_post_featured_image.localFile.childImageSharp
                .fluid
            }
          />
        </div>
        <div className="postExcerpt">
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: post.node.title }} />
          </div>
          <div className="postExcerpt__author">
            <p>
              <span>{moment(post.node.date).format("MMM Do, YYYY")}</span> |{" "}
              <span>
                Article written by {post.node.acf._kon_post_author_name}
              </span>
            </p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: post.node.acf._kon_post_excerpt,
            }}
          />
          <div className="postExcerpt__link">
            <Link to={`/resources/${post.node.slug}`}>Read More</Link>
          </div>
        </div>
      </div>
    </PostCardStyled>
  )
}

export default PostCard
