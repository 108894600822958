import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Hero from "../components/TemplateComponets/Resources/Hero"
import AllPosts from "../components/TemplateComponets/Resources/AllPosts"

const resources = props => {
  const { seoInfo, hero, posts } = props.data
  const location = props.location
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location.pathname}
      />
      <Hero hero={hero} />
      <AllPosts posts={posts} />
    </Layout>
  )
}

export const resourcesQuery = graphql`
  query resourcesPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }
    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _kon_serher_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    posts: allWordpressPost {
      edges {
        node {
          title
          date
          author
          wordpress_id
          slug
          acf {
            _kon_post_excerpt
            _kon_post_author_name
            _kon_post_featured_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default resources
