import React from "react"
import styled from "styled-components"

import PostCard from "./PostCard"
import { medWrapper, H1DarkGrey, colors } from "../../../styles/helpers"

const AllPostsStyled = styled.section`
  padding-top: 7.5rem;

  @media (min-width: 768px) {
    padding-top: 15rem;
  }

  .wrapper {
    ${medWrapper}
  }

  .mainTitle {
    width: 100%;
    text-align: center;
    margin-bottom: 3.8rem;
    padding-bottom: 7.5rem;
    border-bottom: solid 0.2rem ${colors.colorPrimary};

    h2 {
      ${H1DarkGrey};
    }
  }

  .allPostWrap {
    width: 100%;
  }
`

const AllPosts = ({ posts }) => {
  return (
    <AllPostsStyled>
      <div className="wrapper">
        <div className="mainTitle">
          <h2>Resources</h2>
        </div>
        <div className="allPostWrap">
          {posts.edges.map(post => (
            <PostCard key={post.node.wordpress_id} post={post} />
          ))}
        </div>
      </div>
    </AllPostsStyled>
  )
}

export default AllPosts
